import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      color: theme.palette.common.white,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    autocomplete: {
      marginRight: theme.spacing(2),
      width: '220px',
    },
    clearButton: {
      margin: theme.spacing(1),
    }
  }),
)

export default useStyles