import React, { useState, useEffect } from 'react'
import { EditorMode } from '../../utils/AceUtil'
import { DiffEditor as MonacoDiffEditor } from '@monaco-editor/react'
import config from '../../config';

const sampleContent = {
  left: {
    "message": "compare your code",
    "description": "The different line is highlighted"
  },
  right: {
    "message": "compare your code",
    "description": "The diff line is highlighted"
  }
}

interface Props {
  paletteType: string
  editorMode: EditorMode
}

const Editor: React.FC<Props> = (props) => {
  const { paletteType, editorMode } = props 
  const [leftCode, setLeftCode] = useState<string>(JSON.stringify(sampleContent.left, null, 2))
  const [rightCode, setRightCode] = useState<string>(JSON.stringify(sampleContent.right, null, 2))
  const options = {
    originalEditable: true, // for left pane
    readOnly: false,        // for right pane
    renderValidationDecorations: 'off',
  }

  useEffect(() => {
  }, [])

  return (
    <div className={paletteType}>
      <MonacoDiffEditor
        options={options}
        height='80vh'
        theme={paletteType === 'light' ? 'light' : 'vs-dark'}
        original=''
        modified=''
        language={editorMode.name}
      />
    </div>
  )
}

export default Editor