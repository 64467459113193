import React, { useState } from 'react';
import { ThemeProvider, PaletteType } from '@material-ui/core'
import { makeStyles, createStyles, Theme, unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Navigation from './components/Navigation'
import Editor from './components/Editor'
import GoogleAds from './components/GoogleAds'
import Copyright from './components/Copyright'
import { EditorMode } from './utils/AceUtil'
import config from './config'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '6px',
      boxShadow: 'none',
    },
    cardContent: {
      flexGrow: 1,
      padding: '24px',
    },
  }),
)

function App() {
  const [paletteType, setPaletteType] = useState('light')
  const [editorMode, setEditorMode] = useState<EditorMode>(config.defaultLanguages)
  const muiTheme = createMuiTheme({
    palette: {
      type: 'dark' as PaletteType,
      primary: {
        main: '#4782da',
      },
      background: {
        default: '#000',
      }
    },
  })
  const classes = useStyles()

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Container component="header" disableGutters={true} maxWidth={false}>
        <Navigation setPaletteType={setPaletteType} editorMode={editorMode} setEditorMode={setEditorMode} />
      </Container>
      <Container component="main" className={classes.main} maxWidth={false}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Editor paletteType={paletteType} editorMode={editorMode} />
          </Grid>
          <Grid item xs={12}>
            <Box m={4}>
              <GoogleAds currentPath={window.location.pathname} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container component="footer" disableGutters={true} maxWidth={false}>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default App;
