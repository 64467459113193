import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  skelton: {
    display:'inline-block',
    width:'728px',
    height:'90px'
  }
}))

export default useStyles