import React, { useEffect } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import useStyles from './useStyles'

declare global {
  var adsbygoogle: any
}

interface Props {
  currentPath: string
}

const GoogleAds: React.FC<Props> = (props) => {
  const classes = useStyles()
  const isDevelopment = process.env.NODE_ENV === 'development'
  const { currentPath } = props

  useEffect(() => {
    if (window.adsbygoogle && !isDevelopment) {
      try {
        (adsbygoogle = (window as any).adsbygoogle || []).push({})
      } catch (e) {}
    }
  }, [currentPath])

  return (
    <div key={currentPath} className={classes.root}>
      { isDevelopment
        ? <Skeleton variant="rect"
            className={classes.skelton} />
        : <ins className="adsbygoogle"
            style={{display:'block'}}
            data-ad-client="ca-pub-7728872391429065"
            data-ad-slot="3711430943"
            data-ad-format="auto"
            data-full-width-responsive="true">
          </ins>
      }
    </div>
  )
}

export default GoogleAds