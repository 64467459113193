import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import WbSunny from '@material-ui/icons/WbSunny'
import Brightness2 from '@material-ui/icons/Brightness2'
import { EditorMode } from '../../utils/AceUtil'
import config from '../../config'
import useStyles from './useStyles'

interface Props {
  setPaletteType: React.Dispatch<React.SetStateAction<string>>
  editorMode: EditorMode
  setEditorMode: React.Dispatch<React.SetStateAction<EditorMode>>
}

const Navigation: React.FC<Props> = (props) => {
  const { setPaletteType, editorMode, setEditorMode } = props
  const [type, setType] = useState('light')
  const editorModes = config.supportedLanguages
  const classes = useStyles()

  const handlePaletteTypeChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    if (value) {
      setType(value)
      setPaletteType(value)
    }
  }

  const handleEditorModeChange = (event: React.ChangeEvent<{}>, value: string) => {
    const mode = editorModes.filter(mode => mode.name === value)
    if (mode.length > 0) {
      setEditorMode(mode[0])
    }
  }

  return (
    <AppBar component="nav" position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          diff-code.com
        </Typography>
        <Autocomplete
          id='editor-mode'
          size="small"
          disableClearable
          autoComplete
          autoHighlight
          value={editorMode}
          options={editorModes}
          getOptionLabel={(option) => option.name}
          className={classes.autocomplete}
          onInputChange={handleEditorModeChange}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
        <ToggleButtonGroup
          size="small"
          exclusive
          value={type}
          onChange={handlePaletteTypeChange}
          aria-label="text alignment"
        >
          <ToggleButton value="light" aria-label="left aligned">
            <WbSunny fontSize="small" />
          </ToggleButton>
          <ToggleButton value="dark" aria-label="centered">
            <Brightness2 fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
    </AppBar>
  )
}

export default Navigation
